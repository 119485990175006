.moreInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  /* margin-top: 100px; */
  margin-top: 70px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.more-info-title {
  font-size: 23px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}

.end-of-page {
  margin-top: '200px'
}