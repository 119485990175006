/***** MOBILE FIRST *****/

/* - GT Walsheim pro, all fonts in folder if needed */
/* Fonts */
@font-face {
  font-family: 'Pattaya';
  src: local('Pattaya'), url(./Fonts/Pattaya-Regular.ttf) format('truetype');
}

.pattayaFont {
  font-family: 'Pattaya', sans-serif;
}

@font-face {
  font-family: 'GTWalsheimPro-Regular';
  src: local('GTWalsheimPro-Regular'), url(./Fonts/GTWalsheimPro-Regular.ttf) format('truetype');
}


.regularFont,
#regularFont {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
}

@font-face {
  font-family: 'GTWalsheimPro-Medium';
  src: local('GTWalsheimPro-Medium'), url(./Fonts/GTWalsheimPro-Medium.ttf) format('truetype');
}

.mediumFont {
  font-family: 'GTWalsheimPro-Medium', sans-serif;
}

@font-face {
  font-family: 'GTWalsheimPro-Bold';
  src: local('GTWalsheimPro-Bold'), url(./Fonts/GTWalsheimPro-Bold.ttf) format('truetype');
}

.boldFont,
#boldFont {
  font-family: 'GTWalsheimPro-Bold', sans-serif;
}

@font-face {
  font-family: 'GTWalsheimPro-RegularOblique';
  src: local('GTWalsheimPro-RegularOblique'), url(./Fonts/GTWalsheimPro-RegularOblique.ttf) format('truetype');
}

.regularObliqueFont { /* Similar to italic */
  font-family: 'GTWalsheimPro-RegularOblique', sans-serif;
}




body {
  background-color: #071f56 !important;
  font-family: 'GTWalsheimPro-Regular', sans-serif;
}

body * {
  margin: 0;
}




#root {
  width: 100%;
  height: 100%;
  background-color: #071F56;
  padding-top: var(--ion-safe-area-top);
  padding-bottom: var(--ion-safe-area-bottom);
}

.appBackgroundColor {
  background-color: #071f56;
}

.app {
  max-width: 927px; /* Can be changed */
  min-height: 100vh;
  margin: auto;
}


body {
  padding-top: var(--safe-area-inset-top);
  padding-bottom: var(--safe-area-inset-bottom);
  padding-left: var(--safe-area-inset-left);
  padding-right: var(--safe-area-inset-right);
}

:root {
  --safe-area-inset-top: 20px;
  --safe-area-inset-bottom: 20px;
  --safe-area-inset-left: 0px;
  --safe-area-inset-right: 0px;
}


/* .bar-of-progress {
  margin-top: 50px !important;
} */

/* This will apply only to Safari (iOS) */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) {
  _::-webkit-full-page-media, _:future, :root .bar-of-progress {
    margin-top: 50px !important;
  }
}


:root {
  --status-bar-height: 20px;
}


/* This is to make sure there is space at the top on ipphones*/
/* body {
  padding-top: env(safe-area-inset-top, 0);
  padding-right: env(safe-area-inset-right, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);
  padding-left: env(safe-area-inset-left, 0);
} */

/* .topNavBar {
  padding-top: calc(env(safe-area-inset-top, 0) + 10px);
} */

/* .topNavBar {
  
  padding-top: env(safe-area-inset-top);
} */

/* In your CSS file */
/* .topNavBar {
 
  padding-top: 20px; 
} */

/* Style adjustments for iOS devices */
/* .topNavBar.ios {
  padding-top: env(safe-area-inset-top); 
} */

/* Removing default styles */
div {
  position: relative;
}

p {
  margin-bottom: 3px;
  word-break: break-word;
}

a {
  color: unset;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}

.linkHasUnderline a {
  text-decoration: underline;
}

#hasBlueLink a {
  color: #22479f;
}

button {
  background-color: transparent;
  border: none;
  padding: 0px;
  line-height: 15px !important;
}

button:focus {
  outline: none;
}

*:focus {
  outline: none;
}



/* Font sizes */
.fontSize77 {
  font-size: 77px;
}

.fontSize43 {
  font-size: 43px;
}

.fontSize40 {
  font-size: 40px;
}

.fontSize28 {
  font-size: 28px;
}

.fontSize25 {
  font-size: 25px;
}

.fontSize24 {
  font-size: 24px;
}

.fontSize23 {
  font-size: 23px;
}

.fontSize22 {
  font-size: 22px;
}

.fontSize20 {
  font-size: 20px;
}

.fontsize19 {
  font-size: 19px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize13 {
  font-size: 13px;
}

.fontSize12 {
  font-size: 12px;
}

.fontSize11 {
  font-size: 11px;
}

.fontSize10 {
  font-size: 10px;
}

.fontSize9 {
  font-size: 9px;
}

.fontSizeRem {
  font-size: 0.9rem;
}

/* line height */
.lineHeight10 {
  line-height: 10px;
}

.lineHeight11 {
  line-height: 11px;
}

.lineHeight14 {
  line-height: 14px;
}

.lineHeight15 {
  line-height: 15px;
}

.lineHeight16 {
  line-height: 16px;
}

.lineHeight17 {
  line-height: 17px;
}

.lineHeight18 {
  line-height: 18px;
}

.lineHeight20 {
  line-height: 20px;
}

.lineHeight22 {
  line-height: 22px;
}

.lineHeight23 {
  line-height: 23px;
}

.lineHeight24 {
  line-height: 24px;
}

.lineHeight25 {
  line-height: 25px;
}

.lineHeight26 {
  line-height: 26px;
}

.lineHeight28 {
  line-height: 28px;
}

.lineHeight30 {
  line-height: 30px;
}

.lineHeight95Percent {
  line-height: 95%;
}

.lineHeightNormal {
  line-height: normal;
}



/* Font Colors */
.colorWhite,
a.colorWhite,
.colorWhite a {
  color: #ffffff !important;
}

.colorDarkGrey {
  color: #525252;
}


.colorBlack0 {
  color: #524F4F;
}

.colorBlack1 {
  color: #3D3D3D;
}
.colorBlack2 {
  color: #333333;
}
.colorBlack3 {
  color: #262525; /* #292A2B */
}
.colorBlack4 {
  color: #191818;
}




.colorPink {
  color: #FDA4A5;
}


.colorPeach1 {
  color: #F77478;
}
.colorPeach2 {
  color: #F57279;
}
.colorPeach3 {
  color: #f75f65; /* same #F75F64, #f65f64 */
}
.colorPeach4,
.hasColorPeach4Span span,
#colorPeach4 {
  color: #F74A57; /* same #F85258 */
}
.colorPeach5 {
  color: #f74A58;
}

.colorPeach6 {
  color: #FF5D69;
}




.colorBlue1 {
  color: #b9c0ef;
}
.colorBlue2 {
  color: #6F8ED9;
}
.colorBlue3 {
  color: #5D7CC7;
}
.colorBlue4 {
  color: #476ABF;
}
.colorBlue5 {
  color: #3156B2;
}
.colorBlue6 {
  color: #22469F;
}
.colorBlue7 {
  color: #0A2974;
}
.colorBlue8,
#colorBlue8,
#colorBlue8 a,
.hasColorBlue8Span span,
.hasColorBlue8ATags a,
a.colorBlue8,
.colorBlue8 a {
  color: #051D58 !important;
}

.colorPurple {
  color: #7674BE;
}

.contentfulMarkdown.hasColorGreenSpan span {
  color: #16D307;
}






/* Background Colors */
.backgroundColorClear {
  background-color: rgba(0,0,0,0);
}

.backgroundColorWhite {
  background-color: #ffffff;
}

.backgroundColorNone {
  background-color: none;
}

.backgroundColorGrey1 {
  background-color: #DEDDDD;
}

.backgroundColorPink {
  background-color: #FDA4A5;/* 1% same: #FBA4A6 */
}

.backgroundColorPeach1 {
  background-color: #F77478;
}
.backgroundColorPeach2 {
  background-color: #F75F64; /* same #f75f65, #f65f64 */
}
.backgroundColorPeach2Transparent {
  background-color: #F75F641A;
}
.backgroundColorPeach3 {
  background-color: #F85258;
}
.backgroundColorPeach4 {
  background-color: #F74A57;
}
.backgroundColorPeach5 {
  background-color: #f74A58;
}

.backgroundColorTransparent {
  background-color: transparent;
}

.backgroundColorTransparentBlue {
  background-color: rgba(0, 0, 0, 0.2);
}

.backgroundColorBlue1 {
  background-color: #b9c0ef;
}
.backgroundColorBlue2 {
  background-color: #6F8ED9;
}
.backgroundColorBlue2B {
  background-color: #6482ce;
}
.backgroundColorBlue3 {
  background-color: #5D7CC7;
}
.backgroundColorBlue4 {
  background-color: #476ABF;
}
.backgroundColorBlue5 {
  background-color: #3156B2;
}
.backgroundColorBlue6 {
  background-color: #22469F; /* same #22479f */
}
.backgroundColorBlue7 {
  background-color: #0A2974; /* #0a2a74 */
}
.backgroundColorBlue8 {
  background-color: #051D58;
}
.backgroundColorBlue9 {
  background-color: #bac0ef;
}

.backgroundColorBlueGrey1 {
  background-color: #818da9;
}

.backgroundColorPurple {
  background-color: #7674BE;
}


.underline {
  text-decoration: underline;
}







.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.positionFixed {
  position: fixed;
}

.positionStatic {
  position: static;
}

.positionInherit {
  position: inherit;
}

.positionSticky {
  position: sticky;
}

.absoluteTopLeft0 {
  top: 0;
  left: 0;
}

.absoluteTopRight0 {
  top: 0;
  right: 0;
}

.absoluteBottomLeft0 {
  bottom: 0;
  left: 0;
}

.zIndex50 {
  z-index: 50;
}




.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexGrow1 {
  flex-grow: 1;
}

.flexGrow2 {
  flex-grow: 2;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexWrapReverse {
  flex-wrap: wrap-reverse;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifySelfFlexEnd {
  justify-self: flex-end;
}

.justifySpaceAround {
  justify-content: space-around;
}

.justifySpaceEvenly {
  justify-content: space-evenly;
}

.c {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignFlexEnd {
  align-items: flex-end;
}

.alignSelfFlexStart {
  align-self: flex-start;
}

.alignSelfCenter {
  align-self: center;
}

.alignSelfFlexEnd {
  align-self: flex-end;
}



.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}


.alignItemsCenter {
  align-items: center;
}


/* Margin */
.marginAuto {
  margin: 0% auto;
}

.marginLeftRightAuto {
  margin-left: auto;
  margin-right: auto;
}

.marginLeft100 {
  margin-left: 100px;
}

.marginLeft150 {
  margin-left: 150px;
}

.marginLeft170 {
  margin-left: 170px;
}

.marginLeft200 {
  margin-left: 200px;
}

.marginLeft225 {
  margin-left: 225px;
}

.marginLeft3Vw {
  margin-left: 3vw;
}

.marginLeft6Vw {
  margin-left: 6vw;
}

.marginLeft10Vw {
  margin-left: 10vw;
}

.marginLeft12Vw {
  margin-left: 12vw;
}

.marginLeft16Vw {
  margin-left: 16vw;
}

.marginLeft18Vw {
  margin-left: 18vw;
}

.marginLeft4 {
  margin-left: 4px;
}

.marginLeft14vw {
  margin-left: 14vw;
}

.marginLeft16 {
  margin-left: 16px;
}

.marginLeft50 {
  margin-left: 50px;
}

.marginLeft60 {
  margin-left: 60px;
}

.marginLeft70 {
  margin-left: 70px;
}

.marginLeft80 {
  margin-left: 80px;
}

.marginLeft90 {
  margin-left: 90px;
}

.marginLeft150 {
  margin-left: 150px;
}

.margin0 {
  margin: 0px;
}

.marginTopNegative18 {
  margin-top: -18px;
}

.marginTopNegative30 {
  margin-top: -30px;
}

.marginTopNegative45 {
  margin-top: -45px;
}

.marginTopNeg {
  margin-top: -50%;
}

.marginTop3 {
  margin-top: 3px;
}

.marginTop6 {
  margin-top: 6px;
}

.marginTop12 {
  margin-top: 12px;
}

.marginTop23 {
  margin-top: 23px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginTop54 {
  margin-top: 54px;
}

.marginTop75 {
  margin-top: 75px;
}

.marginTop85 {
  margin-top: 85vw !important;
}

.marginTop95 {
  margin-top: 95px;
}

.marginTop135 {
  margin-top: 135px;
}

.marginTop150 {
  margin-top: 150px;
}

.marginTop170 {
  margin-top: 170px;
}

.marginTop180 {
  margin-top: 180px;
}

.marginTop190 {
  margin-top: 190px;
}

.marginTop200 {
  margin-top: 200px !important;
}

.marginTopBottom14 {
  margin-top: 14px;
  margin-bottom: 14px;
}

.marginTop0Bottom50 {
  margin-top: 0px;
  margin-bottom: 50px;
}

.marginTop16Bottom14 {
  margin-top: 16px;
  margin-bottom: 14px;
}

.marginTop26Bottom36 {
  margin-top: 26px;
  margin-bottom: 36px;
}

.marginTopBottom50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.marginTopAuto {
  margin-top: auto;
}

.marginTop10 {
  margin-top: 10px;
}

.marginRightNegative6-7rem {
  margin-right: -6.7rem;
}

.marginRight2 {
  margin-right: 2px;
}

.marginRight4 {
  margin-right: 4px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginRight8 {
  margin-right: 8px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight15 {
  margin-right: 15px;
}

.marginRight17 {
  margin-right: 17px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginBottomNegative20 {
  margin-bottom: -20px;
}

.marginBottomNegative150 {
  margin-bottom: -150px;
}

.marginBottomNegative200 {
  margin-bottom: -200px;
}

.marginBottom0 {
  margin-bottom: 0px;
}

.marginBottom4 {
  margin-bottom: 4px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom7 {
  margin-bottom: 7px;
}

.marginBottom8 {
  margin-bottom: 8px;
}

.marginBottom9 {
  margin-bottom: 9px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom12 {
  margin-bottom: 12px;
}

.marginBottom13 {
  margin-bottom: 13px;
}

.marginBottom14 {
  margin-bottom: 14px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginBottom16 {
  margin-bottom: 16px;
}

.marginBottom18 {
  margin-bottom: 18px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom21 {
  margin-bottom: 21px;
}

.marginBottom22 {
  margin-bottom: 22px;
}

.marginBottom24 {
  margin-bottom: 24px;
}

.marginBottom25 {
  margin-bottom: 25px;
}

.marginBottom26 {
  margin-bottom: 26px;
}

.marginBottom28 {
  margin-bottom: 28px;
}

.marginBottom30 {
  margin-bottom: 30px;
}

.marginBottom32 {
  margin-bottom: 32px;
}

.marginBottom36 {
  margin-bottom: 36px;
}

.marginBottom40 {
  margin-bottom: 40px;
}

.marginBottom45 {
  margin-bottom: 45px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.marginBottom60 {
  margin-bottom: 60px;
}

.marginBottom70 {
  margin-bottom: 70px;
}

.marginBottom74 {
  margin-bottom: 74px;
}

.marginBottom5vh {
  margin-bottom: 5vh;
}

.marginLeftNeg3Percent {
    margin-left: -3%;
}

.marginLeftNeg25Percent {
  margin-left: -25%;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft25 {
  margin-left: 25px;
}

.marginLeft30 {
  margin-left: 30px;
}

.marginLeft40 {
  margin-left: 40px;
}

.marginLeft50 {
  margin-left: 50px;
}

.marginLeft60 {
  margin-left: 60px;
}

.marginLeft65 {
  margin-left: 65px;
}

.marginLeftMinus100 {
  margin-left: -100px;
}

.margin20 {
  margin: 20px;
}

.margin20Percent {
  margin: 20%;
}

.margin21-0 {
  margin: 21px 0px;
}

.margin26-0-21 {
  margin: 26px 0px 21px;
}

.margin26-0 {
  margin: 26px 0px;
}

.margin10 {
  margin:10px;
}







/* padding */
.paddingTop20PercentVw {
  padding-top: calc(100vw * 0.2);
}

.paddingTop23PercentVw {
  padding-top: calc(100vw * 0.23);
}

.paddingTop24PercentVw {
  padding-top: calc(100vw * 0.24);
}

.paddingTop40PercentVw {
  padding-top: calc(100vw * 0.40);
}

.paddingTop45PercentVw {
  padding-top: calc(100vw * 0.45);
}

.paddingTop50PercentVw {
  padding-top: calc(100vw * 0.5);
}

.paddingTop60PercentVw {
  padding-top: calc(100vw * 0.6);
}

.paddingTop70PercentVw {
  padding-top: calc(100vw * 0.7);
}

.paddingTop15PercentVw {
  padding-top: calc(100vw * 0.15);
}

.paddingTop10PercentVw {
  padding-top: calc(100vw * 0.1);
}

.paddingTop3Rem {
  padding-top: 3rem;
}

.paddingTop7PercentVw {
  padding-top: calc(100vw * 0.07);
}

.paddingTop5PercentVw {
  padding-top: calc(100vw * 0.05);
}

.paddingTop0 {
  padding-top: 0px;
}

.paddingTop4 {
  padding-top: 4px;
}

.paddingTop5 {
  padding: 5px;
}

.paddingTop10 {
  padding: 10px;
}

.paddingTop14 {
  padding-top: 14px;
}

.paddingTop16 {
  padding-top: 16px;
}

.paddingTop17 {
  padding-top: 17px;
}

.paddingTop20 {
  padding-top: 20px;
}

.paddingTop23 {
  padding-top: 23px;
}

.paddingTop25 {
  padding-top: 25px;
}

.paddingTop27 {
  padding-top: 27px;
}

.paddingTop40 {
  padding-top: 40px;
}

.paddingTop46 {
  padding-top: 46px;
}

.paddingTop60 {
  padding-top: 60px;
}

.paddingTop65 {
  padding-top: 65px;
}

.paddingTop70 {
  padding-top: 70px;
}

.paddingTop75 {
  padding-top: 75px;
}

.paddingTop80 {
  padding-top: 80px;
}

.paddingTop85 {
  padding-top: 85px;
}

.paddingTop90 {
  padding-top: 90px;
}

.paddingTop93 {
  padding-top: 93px;
}

.paddingTop100 {
  padding-top: 100px;
}

.paddingTop125 {
  padding-top: 125px;
}

.paddingTop150 {
  padding-top: 150px;
}


.paddingTopBottom5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.paddingTopBottom10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.paddingTopBottom15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.paddingTopBottom20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.paddingTopBottom25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.paddingTopBottomAuto {
  padding-top: 25px;
  padding-bottom: 25px;
}

.paddingLeftRightAuto {
  padding-left: auto;
  padding-right: auto;
}

.paddingLeftRight5 {
  padding-left: 5px;
  padding-right: 5px;
}

.paddingLeftRight10 {
  padding-left: 10px;
  padding-right: 10px;
}

.paddingLeftRight15 {
  padding-left: 15px;
  padding-right: 15px;
}

.paddingLeftRight16 {
  padding-left: 16px;
  padding-right: 16px;
}

.paddingLeftRight20 {
  padding-left: 20px;
  padding-right: 20px;
}

.paddingLeftRight25 {
  padding-left: 25px;
  padding-right: 25px;
}

.paddingLeftRight30 {
  padding-left: 30px;
  padding-right: 30px;
}

.paddingLeftRight32 {
  padding-left: 32px;
  padding-right: 32px;
}

.paddingLeftRight35 {
  padding-left: 35px;
  padding-right: 35px;
}

.paddingLeftRight40 {
  padding-left: 40px;
  padding-right: 40px;
}

.paddingLeftRight45 {
  padding-left: 45px;
  padding-right: 45px;
}

.paddingLeftRight54 {
  padding-left: 54px;
  padding-right: 54px;
}

.paddingLeftRight66 {
  padding-left: 66px;
  padding-right: 66px;
}

.paddingLeftRight85 {
  padding-left: 85px;
  padding-right: 85px;
}


.paddingRight10 {
  padding-right: 10px;
}

.paddingRight15 {
  padding-right: 15px;
}

.paddingRight20 {
  padding-right: 20px;
}

.paddingRight23 {
  padding-right: 23px;
}

.paddingRight25 {
  padding-right: 25px;
}

.paddingRight30 {
  padding-right: 30px;
}

.paddingRight36 {
  padding-right: 36px;
}

.paddingRight-20 {
  padding-right: -20px !important;
}

.paddingBottom0 {
  padding-bottom: 0px;
}

.paddingBottom1 {
  padding-bottom: 1px;
}

.paddingBottom7 {
  padding-bottom: 7px;
}

.paddingBottom13 {
  padding-bottom: 13px;
}

.paddingBottom18 {
  padding-bottom: 18px;
}

.paddingBottom20 {
  padding-bottom: 20px;
}

.paddingBottom23Percent {
  padding-bottom: 23%;
}

.paddingBottom25 {
  padding-bottom: 25px;
}

.paddingBottom35 {
  padding-bottom: 35px;
}

.paddingBottom40 {
  padding-bottom: 40px;
}

.paddingBottom43 {
  padding-bottom: 43px;
}

.paddingBottom45 {
  padding-bottom: 45px;
}

.paddingBottom64 {
  padding-bottom: 64px;
}

.paddingLeft5 {
  padding-left: 5px;
}

.paddingLeft-10 {
  padding-left: -10px;
}

.paddingLeft10 {
  padding-left: 10px;
}

.paddingLeft15 {
  padding-left: 15px;
}


.paddingLeft18 {
  padding-left: 18px;
}

.paddingLeft20 {
  padding-left: 20px;
}

.paddingLeft23 {
  padding-left: 23px;
}

.paddingLeft25 {
  padding-left: 25px;
}

.paddingLeft36 {
  padding-left: 36px;
}

.paddingLeft40 {
  padding-left: 40px;
}

.paddingLeft45 {
  padding-left: 45px;
}

.paddingLeft50 {
  padding-left: 50px;
}

.paddingLeft60 {
  padding-left: 60px;
}

.paddingLeft70 {
  padding-left: 70px;
}

.paddingLeft80 {
  padding-left: 80px;
}

.paddingLeft100 {
  padding-left: 100px;
}

.paddingLeft120 {
  padding-left: 120px;
}

.paddingLeft130 {
  padding-left: 130px;
}

.paddingLeft150 {
  padding-left: 150px;
}

.padding0-15 {
  padding: 0px 15px;
}

.padding0-0 {
  padding: 0px 0px;
}

.padding0-20-30 {
  padding: 0px 20px 30px;
}

.padding0-25-25 {
  padding: 0px 25px 25px;
}

.padding0-25-14-45 {
  padding: 0px 25px 14px 45px;
}

.padding0-30-57 {
  padding: 0px 30px 57px;
}

.padding0-36-70 {
  padding: 0px 36px 70px;
}

.padding10-0 {
  padding: 10px 0px;
}

.padding10-20-0 {
  padding: 10px 20px 0px;
}

.padding11-0-62 {
  padding: 11px 0px 62px;
}

.padding17-43-20 {
  padding: 17px 43px 20px;
}

.padding19-30-20 {
  padding: 19px 30px 20px;
}

.padding20 {
  padding: 20px;
}

.padding20-25 {
  padding: 20px 25px;
}

.padding21-0 {
  padding: 21px 0px;
}

.padding24-30-36-25 {
  padding-top: 24px;
  padding-right: 30px;
  padding-bottom: 36px;
  padding-left: 25px;
}

.padding26-0-21 {
  padding: 26px 0px 21px;
}

.padding27-30-70 {
  padding: 27px 30px 70px;
}

.padding28-28-0 {
  padding: 28px 28px 0px;
}

.padding28-39-25-46  {
  padding: 28px 39px 25px 46px;
}

.padding30-34 {
  padding: 30px 34px;
}

.padding30-35-7 {
  padding: 30px 35px 7px;
}

.padding8vh-0-3vh {
  padding: 8vh 0vh 3vh;
}

.padding36-15-48 {
  padding: 36px 15px 48px;
}

.padding37-30-34 {
  padding: 37px 30px 34px;
}

.padding40-0 {
  padding: 40px 0px;
}

.padding40-38 {
  padding: 40px 38px;
}

.padding40-40-36 {
  padding: 40px 40px 36px;
}

.padding56-18 {
  padding: 56px 18px;
}

.padding56-21-30-34 {
  padding: 56px 21px 30px 34px;
}

.padding65-35-35 {
  padding: 65px 35px 35px;
}

.padding90-30-62 {
  padding: 90px 30px 62px;
}

.padding90-0-62 {
  padding: 90px 0px 62px;
}

.padding80-30-62-46 {
  padding: 80px 30px 62px 46px;
}

.padding90-30-72 {
  padding: 90px 30px 72px;
}

.padding90-30-62 {
  padding: 90px 30px 62px;
}

.paddingLeft20Percent {
  padding-left: 20%;
}




/* div width and heights */
.fitContentWidth {
  width: fit-content;
}

.widthAuto {
  width: auto;
}

.width8-4Percent {
  width: 8.4%;
}

.width10Percent {
  width: 10%;
}

.width20Percent {
  width: 20%;
}

.width22Percent {
  width: 22%;
}

.width28Percent {
  width: 28%;
}

.width33Percent {
  width: 33%;
}

.width38Percent {
  width: 38%;
}

.width45Percent {
  width: 45%;
}

.width50Percent {
  width: 50%;
}

.width55Percent {
  width: 55%;
}

.width62Percent {
  width: 62%;
}

.width65Percent {
  width: 65%;
}

.width71Percent {
  width: 71%;
}

.width74Percent {
  width: 74%;
}

.width75Percent {
  width: 75%;
}

.width78Percent {
  width: 78%;
}

.width81Percent {
  width: 81%;
}

.width83Percent {
  width: 83%;
}

.width88Percent {
  width: 88%;
}

.width90Percent {
  width: 90%;
}

.width91Percent {
  width: 91%;
}

.width93Percent {
  width: 93%;
}

.width100Percent {
  width: 100%;
}

.width100HeightAuto {
  width: 100%;
  height: auto;
}

.widthAndHeight-100 {
  width: 100%;
  height: 100%;
}

.widthAndHeight-90 {
  width: 90%;
  height: 90%;
}

.widthAndHeight-80 {
  width: 80%;
  height: 80%;
}

.widthAndHeight-50 {
  width: 50%;
  height: 50%;
}

.width64vw {
  width: 64vw;
}

.width30Percent {
  width: 30%;
}

.width36Percent {
  width: 36%;
}

.width46Percent {
  width: 46%;
}

.width49Percent {
  width: 49%;
}

.width55Percent {
  width: 55%;
}

.width62Percent {
  width: 62%;
}

.width64Percent {
  width: 64%;
}

.width67Percent {
  width: 67%;
}

.width69Percent {
  width: 69%;
}

.width70Percent {
  width: 70%;
}

.width74Percent {
  width: 74%;
}

.width76Percent {
  width: 76%;
}

.width80Percent {
  width: 80%;
}

.width83-4Percent {
  width: 83.4%;
}

.width85Percent {
  width: 85%;
}

.width23 {
  width: 23px;
}

.width25 {
  width: 25px;
}

.width38 {
  width: 38px;
}

.width40 {
  width: 40px;
}

.width14Height14 {
  width: 14px;
  height: 14px;
}


.width16Height16 {
  width: 16px;
  height: 16px;
}

.width30Height30 {
  width: 30px;
  height: 30px;
}

.widthHeight35 {
  width: 35px;
  height: 35px;
}

.widthHeight40 {
  width: 40px;
  height: 40px;
}

.width50Height50 {
  width: 50px;
  height: 50px;
}

.width52Height52 {
  width: 52px;
  height: 52px;
}

.widthHeight17 {
  width: 17px;
  height: 17px;
}

.widthHeight50 {
  width: 50px;
  height: 50px;
}

.widthHeight60 {
  width: 60px;
  height: 60px;
}

.widthHeight63 {
  width: 63px;
  height: 63px;
}

.width90Percent {
  width: 90%;
}

.maxWidth90Percent {
  max-width: 90%;
}

.maxWidth275 {
  max-width: 275px;
}

.maxWidth315 {
  max-width: 315px;
}





.heightAuto {
  height: auto;
}

.minHeight100Percent {
  min-height: 100%;
}

.minHeight100vw {
  min-height: 100vw;
}

.height100vw {
  height: 100vw;
}

.height100Percent {
  height: 100%;
}

.height23 {
  height: 23px;
}

.height25 {
  height: 25px;
}

.height35 {
  height: 35px;
}

.height38 {
  height: 38px;
}

.height60 {
  height: 60px;
}

.height100 {
  height: 100px;
}

.maxHeight45 {
  max-height: 45px;
}

.maxHeight100vw {
  max-height: 100vw;
}

.maxHeight120 {
  max-height: 120px;
}




/* background image width and heights */
.backgroundImgSizeCover {
  background-size: cover;
}

.backgroundImgW100HAuto {
  background-size: 100% auto;
}

.backgroundImgWAutoH100 {
  background-size: auto 100%;
}

.backgroundImgWAutoH77Percent {
  background-size: auto 77%;
}

.backgroundImgWAutoH81Percent {
  background-size: auto 81%;
}


.backgroundImgXYCenter {
  background-position: center;
}

.backgroundImgXCenter {
  background-position-x: center;
}

.backgroundImgX45Percent {
  background-position-x: 45%;
}

.backgroundImgXRightYTop {
  background-position-x: right;
  background-position-y: top;
}

.backgroundXCenterYBottom {
  background-position-x: center;
  background-position-y: bottom;
}

.backgroundImgXLeft {
  background-position-x: left;
}

.backgroundImgYBottom {
  background-position-y: bottom;
}

.backgroundImgY105Percent {
  background-position-y: 105%;
}

.dontRepeatImage {
  background-repeat: no-repeat;
}





.opacity0 {
  opacity: 0;
}





.blue8BoxShadow {
  box-shadow: 2.5px 0px 10px #051d58a6;
}





.borderRadius0 {
  border-radius: 0px;
}

.borderRadius20 {
  border-radius: 20px;
}

.borderRadius3rem {
  border-radius: 3rem;
}

.borderRadius50Percent {
  border-radius: 50%;
}




.borderNone {
  border: none;
}








/* Reusable components */
.smallLogo {
  width: 40px;
  height: 40px;
}




.largeButtonContainer {
  text-align: center;
}

.largeButton {
  min-width: 64vw;
  padding: 9px 20px 9px;
  border-radius: 2rem; /* 17px */
  color: white;
}

.largeButton2 {
  min-width: 64vw;
  padding: 9px 20px 9px;
  border-radius: 2rem; /* 17px */
  color: #FF5D69;
}

.largeButton3 {
  min-width: 64vw;
  max-width: 50px;
  border-radius: 2rem; /* 17px */
  color: white;
  text-align: center;
}
.widthMatchLargeButton {
  width: 64vw;
}




/* Prev and next bottom nav */
.prevAndNextBottomNav {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 10;
}

.prevAndNextBottomNav .navBtn .image {
  width: 30px;
  height: 30px;
  margin-top: 100px;
}

/* This is for PrevAndNextBottomNavTwo.js only for the Next Btn on IntroPage */
.navBtn2 {
  width: 30px;
  height: 30px;
  margin-top: 450px;
}

.prevButtonBottomIntro {
  width: 30px;
  height: 30px;
  margin-top: 400px !important;
  margin-left: 100px !important
}

.prevBtnTop {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 10;
  margin-left: 2rem;
  padding-top: 4rem;
  display: inline-block;
  position: fixed;
}

.prevBtnTopLow {
  width: 40px;
  height: 40px;
  z-index: 10;
  margin-left: 1rem;
  top: 4px;
  padding-top: 3rem;
  position: fixed;
}

.prevBtnTopDivBlue {
  width: 600px;
  background-color: #051D58 !important;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  height: 40px;
  overflow: hidden;
}

.prevBtnTopResponsive {
  padding: 5px 520px 18px 5px;
  }
  
  @media all and (max-width:30em){
  .prevBtnTopResponsive{
  display:block;
  margin:0.4em auto;
  }
  }

  @media all and (min-width:768px){
    .prevBtnTopResponsive{
    display:block;
    margin:0.5em auto;
    }
    }

.prevBtnTopFixedBlue {
  position: fixed;
  display: inline;
  width: 40px;
  height: 40px;
  margin-left: 4vw;
  top: 1px;
  background-color: #051D58 !important;
}

.prevBtnTopFixedWhite {
  position: fixed;
  top: 1px;
  background-color: #fff;
}

.prevBtnTopDivWhite {
  background-color: #fff;
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 20px;
  max-width: 100%;
  position: fixed;
  z-index: 100;
  min-width: 600px;
}

.prevBtnTopDivClear {
  background-color: rgba(0, 0, 0, .0);
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 20px;
  max-width: 100%;
  position: fixed;
  z-index: 100;
  min-width: 600px;
}

.prevBtnTop1 {
  position: absolute;
  width: 10%;
  left: 0px;
  z-index: 1;
  margin-left: 3vw;
  margin-top: -14vw;
  padding-top: 1%;
}

.prevBtnTop2 {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0px;
  z-index: 10;
}

.prevBtnTop3 {
  width: 40px;
 
  height: 40px;
  
  z-index: 10;
  margin-left: 3vw;
  margin-top: 10vw;
  padding-top: 10%;
}


.pinInputs {
  width: 53px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f75f65;
  font-weight: bold;
  background-color: #fff;
  border-radius: 17px;
  text-align: center;
  border: none;
}
.pinInputs::placeholder {
  color: #f75f65 !important;
}




.introPageTwo .prevAndNextBottomNav {
  padding-right: 30px;
  bottom: -5px;
}



.introPropPages .prevAndNextBottomNav {
  /* position: relative; */
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 0px;
}

.introPropPages .largeButtonContainer {
  z-index: 11;
}




/* React calendar */
.react-calendar,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button[disabled],
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--now,
.react-calendar__tile--active {
  background: transparent !important;
  background-color: transparent !important;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
}

.react-calendar__navigation {
  margin-bottom: 0px !important;
}

.react-calendar button {
  color: #ffffff;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  flex-grow: 2;
}

.react-calendar__navigation__prev-button {
  text-align: right;
}

.react-calendar__navigation__next-button {
  text-align: left;
}

.activateNotificationsPage .react-calendar__navigation__prev-button,
.activateNotificationsPage .react-calendar__navigation__next-button  {
  color: transparent;
  position: relative;
}

.activateNotificationsPage .react-calendar__navigation__prev-button::after {
  content: '';
  width: 14px;
  height: 14px;
  background: url('./Images/prevBtnImage-whiteAndBlue.svg');
  background-size: 100% auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.activateNotificationsPage .react-calendar__navigation__next-button::after {
  content: '';
  width: 14px;
  height: 14px;
  background: url('./Images/nextBtnImage-whiteAndBlue.svg');
  background-size: 100% auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: left;
  margin-top: auto;
  margin-bottom: auto;
}

.react-calendar .react-calendar__navigation__label__labelText--from {
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  font-size: 15px;
  line-height: 26px;
  text-transform: capitalize;
}

.react-calendar__month-view__weekdays abbr {
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  font-size: 9px;
  line-height: 26px;
  text-decoration: none !important;
}

button.react-calendar__tile {
  display: grid;
  /* flex-direction: column; */
  position: relative;
}

.react-calendar__tile abbr {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 11px;
  line-height: 26px;
  z-index: 2;
}

.react-calendar__tile--active::before {
  content: '';
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #F74A57;
  border-radius: 50%;
  z-index: 1;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #6678A3;
}









/* Rounded top divs */
.topRoundDiv {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 40px;
}

.bottomRoundDiv {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 40px;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.topRoundDiv2 {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 40px;
  height: 400px;
}

.topRoundDivlonger {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -40px;
  padding-bottom: 150px;
}

.topRoundDiv.hasShadow {
  box-shadow: 0px -5px 10px #0000001C;
}

.topRoundDiv.hasBlueShadow {
  box-shadow: 0px -5px 15px #0A297443;
}





.smallTagDiv {
  display: flex;
  width: fit-content;
  height: 20px;
  align-items: center;
  padding: 0px 11px 0px;
  border-radius: 1rem;
}




/* small(11/11), medium(29/29) and large(44/44) radio buttons */
.smallRadioBtn {
  width: 11px;
  height: 11px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.smallRadioBtn:checked:after {
  width: 7px;
  height: 7px;
  display: block;
  position: relative;
  content: '';
  border-radius: 50%;
}

.smallMediumRadioBtn {
  width: 25px;
  height: 25px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  border: 2px solid #051D58;
}
.smallMediumRadioBtn:checked:after {
  width: 9px;
  height: 9px;
  display: block;
  position: relative;
  content: '';
  border-radius: 50%;
}

.mediumRadioBtn {
  width: 29px;
  height: 29px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}
.mediumRadioBtn:checked:after {
  width: 19px;
  height: 19px;
  display: block;
  position: relative;
  content: '';
  border-radius: 50%;
}

.largeRadioBtn {
  width: 44px;
  height: 44px;
  appearance: none;
  position: relative;
  border-radius: 50%;
  border: none;
  cursor: default;
}
.largeRadioBtn:checked:after {
  width: 28px;
  height: 28px;
  display: block;
  position: relative;
  content: '';
  border-radius: 50%;
}

.radioCheckPeach:checked:after {
  background-color: #F74A57;
}

.radioCheckBlue:checked:after {
  background-color: #051D58;
}






/* Carousel */
.carousel,
.carousel-item {
  flex-grow: 1;
  
}

.carousel-item-2 {
  width: 70vh;
}

/* carousel-item used to have background-color: #ffffff;, removed for testimonials*/

ol.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #edeaea;
  border: none;
  margin-right: 7px;
  margin-left: 7px;
}

ol.carousel-indicators li.active {
  background-color: #f74a58;
}


/* Owl Carousel */

.positionAbsoluteZIndex {
  position: absolute;
  z-index: 1;
}

.noBackgroundColor {
  background-color: transparent !important;
}

.slide-1 {
  position: absolute;
  z-index: 1;
  bottom: 60vw;
  margin-bottom: 1rem;
  left: 17vw
}

.slide-2 {
  position: absolute;
  z-index: 1;
  bottom: 30vw;
  margin-bottom: 1rem;
  left: 17vw
}

/* .slide-3 {
  position: absolute;
  z-index: 1;
  bottom: 10vw;
  margin-bottom: 35vw;
  left: 10vw;
  right: 8vw;
} */

.slide-4 {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  line-height: 14px;
  z-index: 1;
  top: 2.5rem;
  width: 100%;
  left: 1.5rem;
  /* padding-right: -50px !important;
  margin-right: -50px !important; */
}

@media screen and (min-width: 310px){.slide-4{
  font-size: 16px;
  line-height: 18px;
  top: 3rem;
  left: 1.5rem;
}}

@media screen and (min-width: 360px){.slide-4{
  font-size: 18px;
  line-height: 20px;
  top: 4.5rem;
  left: 2rem;
}}

@media screen and (min-width: 390px){.slide-4{
  font-size: 22px;
  top: 4rem;
  line-height: 24px;
}}
@media screen and (min-width: 400px){.slide-4{
  font-size: 24px;
  top: 5rem;
  line-height: 26px;
}}

@media screen and (min-width: 500px){.slide-4{
  font-size: 32px;
  top: 8rem;
  left: 3rem;
  line-height: 32px;
}}

@media screen and (min-width: 760px){.slide-4{
  font-size: 36px;
  top: 9rem;
  left: 3.5rem;
  line-height: 38px;
}}

/* .coral-quotes {
  position: absolute;
  left: 17vw;
  bottom: 86vw;
  width: 27vw;
  height: 28vw;
  z-index: 50;
} */

.black-font {
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: #051D58;
}

/* Tiny-Slider Carousel Testimonials */




.needsBorders {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.borderWidth1 {
  border-width: 1px;
  border-style: solid;
}

.borderWidth4 {
  border-width: 4px;
  border-style: solid;
}

.borderTopWidth1 {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-style: solid;
}

.borderLeftRight0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.borderBottomWidth1 {
  border-bottom-width: 1px;
  border-style: solid;
}

#hasTopBottomPeachBorder {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #F74A57;
  padding: 14px 0px;
}

#hasTopBlueBorder {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #051D58;
  padding-top: 14px;
}

#hasBottomBlueBorder {
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-color: #051D58;
  padding-bottom: 14px;
}



.borderColorWhite {
  border-color: #ffffff;
}

.borderColorPeach4 {
  border-color: #F74A57;
}

.borderColorBlue6 {
  border-color: #22469F;
}

.borderColorBlue8 {
  border-color: #051D58;
}

.borderColorRed {
  border-color: red;
}

.topNavTopDiv {
  width: 100%;
  height: 50px;
  background-color: #051D58;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}



/* Top nav bar */
.topNavBar {
  z-index: 100;
  display: inline;
  position: fixed;
  /* padding-top: 50px; */
  /* max-width: 600px;
  height: 60px; */
}


.topNavBar2 .containerDivs {
  width: 40px;
  height: 40px;
}

.topNavBar2 .containerDivs.spaceTop {
  padding-top: 5px;
  height: 35px;
  margin-top: 0px;
}

.topNavBar2 .hamburgerMenuIcon {
  width: 24px;
  height: auto;
}

.topNavBar2 .bellIcon {
  margin-right: 2px;
}

.topNavBar2 .newNotificationCircle,
.activateNotificationsPage .newNotificationCircle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}


/* Hamburger menu */
.hamburgerMenuContainer .bm-overlay {
  background: none !important;
}

.hamburgerMenuContainer *:focus {
  outline: none;
}






/* For pages */
.minPageHeight100vh {
  min-height: 100vh;
}

.minPageHeight135vh {
  min-height: 135vh;
}

.minPageHeight150vh {
  min-height: 150vh;
}


.pagesInnerDiv {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
}


.pageWithBottomMainNav {
  padding-bottom: 50px;
}




/* Contentful Markdown */
.contentfulMarkdown p {
  margin-bottom: 1em;
}

/* this is not the color just a marker  */
.contentfulMarkdown.psMargin0 p {
  margin-bottom: 0px;

}

.contentfulMarkdown.psMargin5 p {
  margin-bottom: 5px;
}

.contentfulMarkdown ul,
.contentfulMarkdown ol {
  padding-left: 1.4em;
  margin-bottom: 1em;
}

.contentfulMarkdown.circleBullets ul {
  list-style-type: circle;
}

.contentfulMarkdown strong {
  font-family: 'GTWalsheimPro-Bold', sans-serif;
}

.contentfulMarkdown.hasBookmarkIcon img {
  content:url('./Images/bookmark-icon-peach.svg');
  width: 11px;
  height: 14px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: bottom;
}

.contentfulMarkdown.hasBookmarkIcon.bookmarkMargin0 img {
  margin-right: 0px;
}

.contentfulMarkdown.hasBookmarkIcon.bookmarkAlignFlexStart img {
  align-self: flex-start;
}

.contentfulMarkdown.hasImportantBullets ul {
  list-style: none;
  padding-left: 10px;
}
.contentfulMarkdown.hasImportantBullets ul li {
  display: flex;
  flex-direction: row;
}
.contentfulMarkdown.hasImportantBullets ul li::before {
  content: url('./Images/important-icon-peach.svg');
  width: 25px;
  height: 25px;
}

.contentfulMarkdown.hasImportantBullets ul li p {
  margin-left: 10px;
}



.underlineLink {
  text-decoration: underline;
  text-decoration-color: 'white';
}




/* Bottom nav bar */
.bottomNavBar {
  width: 100%;
  height: 65px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;
}




/* Intro value proposition pages */





.headerArea { /* ( ~ 180px) */
  min-height: calc(100vw * 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.headerArea.justText {
  min-height: auto;
}










.stepsMenuPage .topRoundDiv,
.notificationsMenuPage .topRoundDiv,
.contraceptionAssessmentOptionsMenuPage .topRoundDiv,
.contraceptionMenuPage .topRoundDiv {
  margin-top: -45px;
}





/* Abortion Step Pages */
.abortionStepPages .headerArea {
  min-height: calc(100vw * 0.686);
}

.abortionStepPages .headerArea.empty { /* 72px */
  min-height: 72px;
}

.abortionStepPages .topRoundDiv,
.selfCarePage .topRoundDiv {
  margin-top: 0px !important;
}





/* Self care pages */
.selfCareSlides.slide1 .headerArea {
  min-height: calc(100vw * 0.32);
}
.selfCareSlides.slide3 .headerArea {
  min-height: calc(100vw * 0.56);
}
.selfCareSlides.slide4 .headerArea {
  min-height: calc(100vw * 0.35);
}

.selfcareMenuImage {
  width: 100%;
  top: 109px;
  left: 68px;
  width: 224px;
height: 216px;
}

.selfCareSlidePadding {
  padding-top: 24vw;
}

@media screen and (min-width: 250px) and (max-width: 800px){.selfCareSlidePadding{
  /* padding-top: 30vw; */
  margin-top: 10vw;
}}



/* Notifications */






/* activateNotificationsPage */
.activateNotificationsPage .react-date-picker__inputGroup {
  display: none;
}

.activateNotificationsPage .datePickerBtn,
.activateNotificationsPage .timePickerBtn {
  position: absolute;
  top: 0;
  left: 0;
}

.activateNotificationsPage .react-date-picker__calendar {
  width: 100% !important;
  margin: 0% auto !important;
}

.activateNotificationsPage .react-calendar {
  border-radius: 1.5rem;
}

.activateNotificationsPage .inputContainer * {
  border: none !important;
}

.activateNotificationsPage .react-date-picker__calendar-button,
.activateNotificationsPage .timePickerBtn {
  width: 100%;
  height: 100%;
}

.activateNotificationsPage .react-date-picker__calendar-button svg {
  display: none;
}

.activateNotificationsPage .react-calendar {
  background-color: #051D58 !important;
}


.activateNotificationsPage .timePickerBtn input[name='time'] {
  width: 100%;
  left: 0;
}

.activateNotificationsPage .react-time-picker__inputGroup__input {
  color: #ffffff;
}


.activateNotificationsPage .phoneInput::placeholder {
  color: #051D58;
}












/* Any Phone Width in Landscape mode */
@media screen and (max-height: 480px) {

  .landscapeWidth67Percent {
    width: 67% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .landscapeWidth75Percent {
    width: 75% !important;
    margin-left: auto;
    margin-right: auto;
  }



  .landscapePositionRelative {
    position: relative !important;
  }



  .landscapeMarginTop95 {
    margin-top: 95px !important;
  }



  .largeButton {
    min-width: calc(600px * 0.37) !important;
  }
  .widthMatchLargeButton {
    width: calc(600px * 0.37) !important;
  }





  .buttonWidth55Percent {
    width: calc(600px * 0.55) !important;
  }




  .hamburgerMenuContainer .bm-item-list {
    padding-bottom: 65px !important;
  }
}



/* Tablets - Desktops */
@media screen and (min-width: 580px) { /*(min-height: 927px) { */
 
  /* Hamburger menu */

  .app {
    width: 600px !important;
    max-width: 600px !important;
    min-height: 900px !important;
    height: 900px !important;
  }


  .width64vw {
    width: calc(600px * 0.64) !important;
  }

  .minHeight100vw {
    min-height: 600px !important;
  }

  .height100vw {
    height: 600px !important;
  }

  .maxHeight100vw {
    max-height: 600px !important;
  }



  .minPageHeight100vh {
    min-height: 100% !important;
  }




  .pageWithBottomMainNav {
    padding-bottom: 0px !important;
  }





  /* reusable components */
  .largeButton {
    min-width: calc(600px * 0.37) !important;
  }
  .widthMatchLargeButton {
    width: calc(600px * 0.37) !important;
  }




  .buttonWidth55Percent {
    width: calc(600px * 0.55) !important;
  }



  .topRoundDiv {
    margin-top: -50px !important;
  }



  .bottomNavBar {
    width: 600px !important;
    position: relative !important;
    left: unset !important;
  }


  .bottomNavBarTwo {
    margin-left: -35px;
  }
  

  /* Hamburger menu */
  .hamburgerMenuOpacity0 {
    opacity: 0;
    transition: opacity 0.4s;
  }

  .hamburgerMenuOpacity1 {
    opacity: 1;
    transition: opacity 0.4s;
  }

  .hamburgerMenuContainer .bm-menu-wrap {
    width: calc(600px * 0.7) !important;
  }





  .headerArea { /* ( ~ 180px) */
    min-height: calc(600px * 0.49) !important;
  }

  .headerArea.justText {
    min-height: auto !important;
  }




  /* abortion steps pages */
  .abortionStepPages .headerArea.empty {
    min-height: 72px !important;
  }
}

/* Abortion Buckets */

.roundedBoxTop {
  top: 50px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #F75F64 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxBottom {
  top: 160px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #B9C0EF 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxMiddleLight {
  top: 80px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #22469F 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxMiddleDark {
  top: 110px;
  width: 295px;
  height: 126px;
  margin-top: 20px;
  background: #051D58 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.roundedBoxTransparentPink {
  width: 295px;
  height: 72px;
  margin-top: 20px;
  background: #F75F641A  0% 0% no-repeat padding-box;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  opacity: 1;
  border: 0.7px solid #F75F64;
}

.smallRoundedBoxPink {
  width: 78px;
  height: 72px;
  background: #F75F64  0% 0% no-repeat padding-box;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  opacity: 1;
  border: 1px solid #F75F64;
  padding: 0px 0px 0px 0px;
}

.roundedBoxTransparentLightBlue {
  width: 295px;
  height: 72px;
  margin-top: 20px;
  background: #6F8ED915  0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
  border: 0.7px solid #22469F;
}

.smallRoundedBoxLightBlue {
  width: 78px;
  height: 72px;
  background: #22469F  0% 0% no-repeat padding-box;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  opacity: 1;
  border: 1px solid #22469F;
  padding: 0px 0px 0px 0px;
}

.roundedBoxTransparentDarkBlue {
  width: 295px !important;
  height: 72px !important;
  margin-top: 20px !important;
  background: #051D581A  0% 0% no-repeat padding-box !important;
  border-radius: 17px !important;
  opacity: 1 !important;
  border: 0.7px solid #051D58 !important;
}

.extraSmallRoundedBoxLilac {
  width: 13px !important;
  height: 72px !important;
  background: #B9C0EF  0% 0% no-repeat padding-box !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  opacity: 1 !important;
  border: 1px solid #B9C0EF!important;
  padding: 0px 0px 0px 0px !important; 
}

.roundedBoxTransparentLilac {
  width: 295px !important;
  height: 72px !important;
  margin-top: 20px !important;
  background: #B9C0EF1A  0% 0% no-repeat padding-box !important;
  border-radius: 17px !important;
  opacity: 1 !important;
  border: 0.7px solid #B9C0EF !important;
}

/* roundedCardLilac is the custom CSS for Bootstrap Accordion in Abortion Buckets FAQ */

.roundedCardLilac {
  border: none !important;
  background-color: none !important;
  width: 295px !important;
  margin-top: 20px !important;
}

.roundedCardLilacOpenShortest {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 280px !important;
}


.roundedCardLilacOpenShort {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 300px !important;
}

.roundedCardLilacOpenLessShort {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 350px !important;
}

.roundedCardLilacOpenMedium {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 370px !important;
}

.roundedCardLilacOpenMedium400 {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 400px !important;
}

.roundedCardLilacOpen {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 450px !important;
}

.roundedCardLilacOpenMediumShort {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 470px !important;
}

.roundedCardLilacOpenMediumShort500 {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 500px !important;
}

.roundedCardLilacOpenMediumShort550 {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 550px !important;
}

.roundedCardLilacOpenMediumLong {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 600px !important;
}

.roundedCardLilacOpenMediumLonger {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 700px !important;
}

.roundedCardLilacOpenLong {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 750px !important;
}

.roundedCardLilacOpenLonger {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 800px !important;
}

.roundedCardLilacOpenLongest {
  border: none !important;
  background-color: none !important;
  /* border: 1px solid #B9C0EF !important; */
  width: 295px !important;
  min-height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
  padding-bottom: 830px !important;
}

.abortionBucketFAQAccordion {
 border: 1px solid #B9C0EF !important;
  /* border-top-left-radius: none !important;
  border-top-right-radius: none !important;
  border-bottom-left-radius: 18px !important;
  border-bottom-right-radius: 18px !important; */
  width: 295px !important;
  border-radius: 18px;
  /* border-top: none !important;
  border-bottom: 1px solid #B9C0EF !important ;
  border-left: 1px solid #B9C0EF !important;
  border-right: 1px solid #B9C0EF !important; */
  margin-bottom: 50px !important;
}

.smallRoundedBoxDarkBlue {
  width: 78px !important;
  height: 72px !important;
  background: #051D58  0% 0% no-repeat padding-box !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  opacity: 1 !important;
  border: 1px solid #051D58 !important;
  padding: 0px 0px 0px 0px !important; 
}

.smallDivRoundedBox {
  width: 295px;
  height: 72px;
}

.textWidth {
  width: 145px;
}

.textWidth2 {
  width: 160px;
}

.textWidth3 {
  width: 175px;
}

.textWidth4 {
  width: 180px;
}

.displayInline {
  display: inline-block;
}


.roundedBoxNarrowLilac {
width: 295px;
height: 69px;
margin-top: 20px;
background: #B9C0EF 0% 0% no-repeat padding-box;
border-radius: 17px;
opacity: 1;
}

.roundedBoxNarrowPeach {
  width: 295px;
  height: 69px;
  margin-top: 20px;
  background: #F75F64 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
  }

  .roundedBoxNarrowMediumBlue {
    width: 295px;
    height: 69px;
    margin-top: 20px;
    background: #22469F 0% 0% no-repeat padding-box;
    border-radius: 17px;
    opacity: 1;
    }

.roundedBoxNarrowDarkBlue {
      width: 295px;
      height: 69px;
      margin-top: 20px;
      background: #051D58 0% 0% no-repeat padding-box;
      border-radius: 17px;
      opacity: 1;
  }

.smallRoundedBoxNarrow {
  width: 78px;
  height: 69px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 0px 0px 0px 0px;
}

.aboutSurgicalAbortionPage {
  background-color: #B9C0EF !important;
}

.underconstrucionPage {
  background-color: #051D58 !important;
}

.textBox {
  width: 270px;
  height: 150px;
  text-align: center !important;
}

.bottomLinkLeft {
  /* position: absolute; */
  top: 50%;
  left: 50px;
  transform: translate(-50%, -50%);
}

.whiteSpaceNo {
  white-space: nowrap;
}

/* vertical align helps place the images within the flexbox */
.verticalAlign {
  position: absolute;
  top: 69%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlignHigher {
  position: absolute;
  top: 74%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlign2 {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.verticalAlign3 {
  position: absolute;
  top: 55%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlign4 {
  position: absolute;
  top: 53%;
  left: 73%;
  transform: translate(-50%, -50%);
}

.verticalAlign5 {
  position: absolute;
  top: 60%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.verticalAlign6 {
  position: absolute;
  top: 63%;
  left: 76%;
  transform: translate(-50%, -50%);
}

/* This is the bs.Prefix for Alert.Heading in TermsOfUseAlert*/
/* .termsofuse-alert {
  background-color: white !important;
  top: 180px;
  bottom: 20px;
  left: 1%;
  right: 15px;
  margin-bottom: 20px;
  max-width: 570px;
  min-height: 800px;
  border-radius: 45px;
  border-bottom-left-radius: 45px !important;
  border-bottom-right-radius: 45px !important;
  opacity: 1;
  z-index: 9999999;
  position: relative;
}

@media screen and (max-width: 767px) {
  .termsofuse-alert {
    top: 100px;
    left: 1%;
    right: 10px;
    max-width: 300px;
    min-height: auto;
  }
}

@media screen and (max-width: 300px) {
  .termsofuse-alert {
    top: 100px;
    left: 1%;
    right: 10px;
    max-width: 200px;
    min-height: auto;
  }
} */
.termsofuse-bottomRoundDiv {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: -10px;
  padding-bottom: 5px;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
  max-width: 380px;
  margin-right: 16px;
  
}

@media screen and (max-width: 300px) {
  .termsofuse-bottomRoundDiv {
    margin-top: -20px;
    scroll-padding-left: -10px;
    padding-right: 5px;
  }
}

@media screen and (min-width: 300px) {
  .termsofuse-bottomRoundDiv {
    margin-top: -20px;
    scroll-padding-left: -10px;
    margin-right: 50px;
    
  }
}

.termsofuse-div-padding {
  padding-left: 50%;
  margin-left: 15%;
}

@media screen and (max-width: 361px) {
  .termsofuse-div-padding {
    padding-left: 1px !important;
    margin-left: -10px !important;
  }
}

@media screen and (min-width: 400px) {
  .termsofuse-div-padding {
    padding-left: 1% !important;
    margin-left: 4% !important;
  }
}

@media screen and (max-width: 8210px) {
  .termsofuse-div-padding {
    padding-left: 1% !important;
    margin-left: 14% !important;
  }
}

@media screen and (min-width: 1000px) {
  .termsofuse-div-padding {
    padding-left: 10% !important;
    margin-left: 10% !important;
  }
}

@media screen and (min-width: 300px) {
  .termsofuse-div-padding {
    padding-left: 5%;
    
  }
}


/* This is the class for headerImage in TermsOfUseAlert*/
.termsofuse-icon {
  position: relative;
  top: -110px;
  left: 35%;
  width: 28%;
}

@media screen and (max-width: 767px) {
  .termsofuse-icon {
    top: -80px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
/* This is the bs.Prefix for Alert.Heading in TermsOfUseAlert*/
.termsofuse-alert-heading { 
  color: #F74A57;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 25px;
  position: absolute;
  top: -30px;
  left: 50%;
  width: 200px;
  height: 89px;
  transform: translate(-50%, -50%);
}

/* This is the class for paragraph in TermsOfUseAlert*/
.termsofuse-alert-text {
  /* border: 0; */
  margin-left: 16px; /* Adjust this value for left padding */
  margin-right: 16px;
  margin-bottom: -160px;
  color: #071F56;
  top: 130px;
  min-width: 250px;
  max-width: 300px;
  left: 45%;
  text-align: center;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
  
  border-bottom: 1.5px solid #071F56;
  border-top: 1.5px solid #071F56;
}

@media screen and (max-width: 300px) {
  .termsofuse-alert-text {
    top: 320px;
    min-width: 200px;
    max-width: 200px;
    margin-left: 11px;  
  }
}

/* @media screen and ( max-width:350px) {
  .termsofuse-alert-text {
    top: 330px;
    min-width: 50px;
    margin-left: 15px;  
  }
} */

@media screen and (min-width: 300px) {
  .termsofuse-alert-text {
    top: 290px;
    margin-left: 10px; 
    min-width: 200px;
    max-height: 300px;
    font-size: small;
  }
}

@media screen and (min-width: 412px) {
  .termsofuse-alert-text {
    top: 300px;
    min-width: 200px;
    max-width: 200px;
    margin-left: 11px;  
    font-size: 14px;
  }
}

@media screen and (min-width: 540px) {
  .termsofuse-alert-text {
    top: 300px;
    left: 44%;
    min-width: 280px;
    transform: translate(-50%, -50%);
    font-size: medium;
  }
}

@media screen and (min-width: 768px) {
  .termsofuse-alert-text {
    /* top: 180px; */
    left: 44%;
    max-width: 250px;
    transform: translate(-50%, -50%);
    font-size: medium;
  }
}

@media screen and (min-width: 992px) {
  .termsofuse-alert-text {
    /* top: 180px; */
    left: 44%;
    min-width: 300px;
    max-width: 350px;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 1200px) {
  .termsofuse-alert-text {
    /* top: 180px; */
    left: 46%;
    max-width: 250px;
    transform: translate(-50%, -50%);
  }
}

/* Hide the original checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Create a custom box */
.custom-checkbox label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 1px;
  margin-top: 6px;
  padding-top:-8px;
  border: 2px solid #F85258;
  background-color: #fff;
  vertical-align: middle;
  color: #F85258;
}

/* @media screen and (min-width: 300px) {
  .custom-checkbox label::before {
    width: 16px;
    height: 16px;

  }
} */


/* Style the custom box when checked */
.custom-checkbox input[type="checkbox"]:checked + label::before {
  border-color: #F85258;
  background-color: #F85258;
}

/* This is the custom styling for the white arrow in the checkbox*/
.custom-checkbox label::after {
  top: 0.65rem;
  left: -1.4rem;
}

.custom-checkbox label {
  color: #F85258;
}

.termsofuse-alert-checkbox-text {
  color: #051D58;
  margin-top: -6px;
  left: 12%;  
  padding-top: -8px;
  padding-bottom: 10px;
}

@media screen and (max-width: 374px) {
  .termsofuse-alert-checkbox-text {
    font-size: small !important;
    max-width: 190px;
  }
}

/* This is the Button with buttonText in TermsOfUseAlert*/
.termsofuse-button {
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #071F56;
  color: white !important;
  border-radius: 45px;
  width: 230px;
  height: 34px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 80px;
}

@media screen and (min-width: 250px) {
  .termsofuse-button {
    margin-top: 80px;
    width: 180px;
    height: 25px;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: -10px;
  }
}

@media screen and (min-width: 325px) {
  .termsofuse-button {
    margin-top: 60px;
    width: 210px;
    height: 34px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: -10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media  screen and (min-width: 576px) {
  .termsofuse-button {
    margin-top: 80px;
    width: 210px;
    height: 34px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (min-width: 768px) {
  .termsofuse-button {
    width: 220px;
    height: 36px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 992px) {
  .termsofuse-button {
    margin-top: -30px;
    width: 240px;
    height: 34px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .termsofuse-button {
    width: 230px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 80px;
  }
}

/* Set lower opacity for the disabled button */
.termsofuse-button-opacity[disabled] {
  opacity: 0.22; /* Adjust this value to your desired opacity */
}

/* Set full opacity for the enabled button */
.termsofuse-button-opacity:not([disabled]) {
  opacity: 1;
}

/* This is the class of the div for the Form.Check, Form.Check.Label
and checkBox Contentful text for TermsOfUseAlert*/
.form-group-container {
  padding-bottom: 20px;
  margin-top: 17rem;
  margin-left: 10px;
  max-width: 250px;
  left: 6%;
}

@media screen and (min-width: 250px){
  .form-group-container{
  top: 6.5rem;
  left: 7px;
  max-width: 210px;
}}

@media screen and (min-width: 300px){
  .form-group-container{
  top: 2rem;
  left: 5%;
  max-width: 220px;
  padding-bottom: 1px;
  margin-bottom: -20px;
}}

@media screen and (min-width: 540px){
  .form-group-container{
  top: 1rem;
  left: 2%;
  padding: 1rem;
  width: 272px;
}}

@media screen and (min-width: 768px){
  .form-group-container{
  top: 1rem;
  left: 10%;
  padding: 1rem;
  width: 300px;
}}

@media screen and (min-width: 992px){
  .form-group-container{
  top: -1rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
}}

@media screen and (min-width: 1200px){
  .form-group-container{
  top: 3rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
  margin-top: 13rem;
}}


.WHOLink {
  /* text-decoration: underline; */
  font-size: 14px;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  text-align: left !important;
  align-items: flex-start !important;
  margin-right: 3rem !important;
  padding-left: 0 !important;
}

.WHOLink-div {
  padding-top: 30px;
  padding-bottom: -10px;
  margin-bottom: -20px;
  margin-left: -150px;
  color: #051D58 !important;
}


.WHOLink a {
  line-height: 2;
}

.sourceTitle {
  text-decoration: none !important;
}



.defaultColor {
  color: #000; /* or whatever your default color is */
}

/* @media screen and (min-width: 250px){
  .WHOLink-div{
    margin-left: 140px;
}} */

@media screen and (min-width: 250px){
  .WHOLink-div{
    /* margin-left: -5px; */
    margin-left: 5px;
    margin-bottom: 20px;
    padding-top: 10px;
    margin-top: 10px;
    margin-right: -50px !important;
}}

@media screen and (min-width: 250px){
  .WHOLink{
    font-size: 12px;
    
}}

@media screen and (min-width: 350px){
  .WHOLink-div{
    /* margin-left: -10px; */
    margin-left: 10px;
    margin-bottom: 40px;
    padding-top: 10px;
    margin-top: 10px;
    margin-right: -50px !important;
}}


@media screen and (min-width: 376px){
  .WHOLink-div{
    /* margin-left: -5px; */
    margin-left: 5px;
    margin-bottom: 40px;
    padding-top: 10px;
    margin-top: 10px;
}}

@media screen and (min-width: 400px){
  .WHOLink{
    font-size: 13px;
    
}}

/* @media screen and (min-width: 768px){
  .WHOLink{
  top: 1rem;
  left: 10%;
  padding: 1rem;
  width: 300px;
}}

@media screen and (min-width: 992px){
  .WHOLink{
  top: -1rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
}}

@media screen and (min-width: 1200px){
  .WHOLink{
  top: 3rem;
  left: 10%;
  padding: 1rem;
  width: 272px;
  margin-top: 13rem;
}} */

.pin-set-up-margin-left {
  margin-left: 12vw;
}

@media screen and (min-width: 760px) {
  .pin-set-up-margin-left {
    margin-left: 5vw;
  }
}

@media screen and (min-width: 900px) {
  .pin-set-up-margin-left {
    margin-left: 4vw;
  }
}

/* Removing default background color of Owl Carousel Slide for Testimonials page*/

.owl-theme {
  background-color: transparent !important;
}
.owl-theme .owl-item {
  background-color: transparent !important;
}

.dots-container {
  padding-top: 30px;
}

