.language-selection {
  display: flex;  
  align-items: center; /* Aligns text and checkmark vertically */
  padding: 15px 0; 
  border-bottom: 1px solid #ccc;  
  cursor: pointer;
}

/* Hide the default radio button */
.language-selection input[type="radio"] {
  display: none;
  margin: 0;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;  
  height: 20px;  
  border-radius: 50%;
  border: 1px solid #ccc;  
  margin-left: auto; /* Pushes the checkmark to the far right */
  cursor: pointer;
}

/* Style when a radio button is checked */
.language-selection input[type="radio"]:checked + .checkmark {
  background-color: #f74b58;
  border-color: #f74b58;  
}

.language-selection input[type="radio"]:checked + .checkmark::after {
  content: "✓";
  color: white;
  font-size: 14px;
  line-height: 20px; /* Align checkmark vertically */
}
