.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align the container at the top */
  height: 100vh;
  padding-top: 80px; /* Add some padding to the top of the container */
}

/* .h1Text {
  color: #FFF;
  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
} */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align the container at the top */
  height: 100vh;
  padding-top: 20px; /* Add some padding to the top of the container */
}

.logo {
  width: 57px;
  height: 57px;
  object-fit: contain;
/*     position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.heading {
  font-size: 28px;
  color: #fff;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-top: 10px;
  text-align: center;
}

.subheading {
  font-size: 28px;
  color: #fff;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-top: 0px;
  text-align: center;
  margin-bottom: 50px;
}

.button-english {
  width: 100%;
  height: 50px;
  background-color: #FFFFFF12;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  font-size: 18px;

  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

.button-spanish {
  width: 100%;
  height: 50px;
  background-color: #FFFFFF12;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

/* Hide the default radio button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Create the custom radio button design */
input[type="radio"] + label {
  position: relative;
  padding-left: 30px; /* Adjust this value to control the spacing between the radio and label text */
  cursor: pointer;
  font-family: 'GTWalsheimPro-Regular', sans-serif; 
}

/* Custom radio button circle (unchecked state) */
input[type="radio"] + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 2px solid #fff; /* White border for the circle */
  border-radius: 50%;
  box-sizing: border-box; /* Ensure the dot doesn't affect the size of the circle */
}

/* Custom radio button dot (checked state) */
input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  left: 5px; /* Adjust this value to control the horizontal position of the dot */
  top: 50%; /* Adjust this value to control the vertical position of the dot */
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #F74A57; /* Red dot inside the circle */
  border-radius: 50%;
}


.radio-container {
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

.radio-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 300px; 
  margin-top: 10px;
}

.radio-item label {
  flex-grow: 1; 
  margin-right: 10px; 
  color: white; 
}

.radio-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease-in-out;
}

.continueButton {
  margin-top: 50px;
  color: white;
  background-color: rgba(255, 255, 255, 0.20);
  border-radius: 17px; 
  border: none;
  padding: 10px 70px;
  cursor: pointer;
  width: 230px;
  height: 34px;
  flex-shrink: 0;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
}

.continueButton:active {
  background-color: #F74A57; /* Change to this color when clicked */
  transition: background-color 0.3s ease-in-out;
}

.buttonDisabled {
  background-color: rgba(255, 255, 255, 0.1); /* Lighter color when disabled */
  cursor: not-allowed; /* Cursor indicates the button is disabled */
}

/* Style for the active button */
.buttonActive {
background-color: #F74A57; /* Reddish color when active */
cursor: pointer;
}