.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 70px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.steps-title {
  font-size: 23px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-bottom: 18px;
  text-align: center; 
  padding-top: 20px;
}

.end-of-accordion {
	margin-top: 90px;
}

.accordion-break {
  margin-top: 13px;
}
