.planCContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 100px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.plan-c-title {
  font-size: 23px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-bottom: 8px;
  text-align: center; 
  padding-top: 20px;
}

.plan-c-page-description {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 14px;
  color: #524F4F;
  margin-bottom: 15px;
}

.last-button {
  display: flex;
  justify-content: flex-end; 
  margin-top: 20px; 
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  background-color: #051D58;
  color: white; 
  font-size: 14px;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.last-button a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.end-of-page {
  margin-top: 200px;
}

.accordion-break {
  margin-top: 13px;
}

