.pillsAndClinicsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.pills-and-clinics-title {
  font-size: 23px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-bottom: 8px;
  padding-top: 20px;
}

.pills-and-clinics-page-description {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 14px;
  color: #524F4F;
  background-color: #FAE7E7;
  border-radius: 12px;
  padding: 20px 10px;
  margin-top: 20px;
 
}

.pills-and-clinics-subtitle {
  font-size: 16px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-top: 10px;
  margin-bottom: 5px;
}

.help-line-container {
  display: flex;
  align-items: center;
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 14px;
  color: #524F4F;
  background-color: #E5F1FA;
  border-radius: 12px;
  padding: 20px 10px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.phone-icon {
  margin-left: auto;
  height: 20px;
  width: 20px;
}


.end-of-page {
  margin-top: 100px;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 12px;
  margin-top: 60px;
  margin-bottom: 0px;
  background-color: #fff;
  padding-top: 35px;
  justify-content: space-between;
}

.dotsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  margin-bottom: 0px;
  
}

.custom-dot {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1px;
  padding: 3px 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-dot-word {
  margin-right: 3px;
}

slick-dots {
  display: flex;
  justify-content: center; 
}

.slick-dots li {
  margin: 0 5px; 
  list-style-type: none; 
}

.active {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
	margin-bottom: 0px;
}

.link-button {
  color: #051D58;
  border-radius: 20px;
  padding: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 22px;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 5px; 
  white-space: nowrap;
  width: auto !important; /* Override the width */
  display: inline-flex !important; /* Override the display property of the elements */
}

.link-button:last-child {
  margin-right: 0; 
}

.link-button:hover {
  color: #051D58; 
}

.link-button.active {
  background-color: #051D58;
  color: #fff; 
  height: 38px; 
}



/* Media query for smaller screens */
@media (max-width: 768px) {
  .link-button {
    padding: 5px 10px; 
    font-size: 10px; 
  }
}

.variable-width {
  width: 200px;
}

.slide-item {
  margin-right: 20px; 
}

