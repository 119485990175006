.resourcesHomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  /* margin-top: 100px; */
  margin-top: 70px;
}

.cardsContainer {
  display: flex;
  flex-direction: center; 
  align-items: center; 
}

.resources-home-page-title {
  font-size: 23px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-bottom: 10px;
  padding-top: 20px;
}

.resources-home-page-description {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 14px;
  color: #524F4F;
  margin-bottom: 15px;
}

.resource-home-subtitle {
  font-size: 16px;
  color: #051D58;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}

.resources-home-dropDown-subtitle {
  font-size: 15px;
  color: #051D58  !important;
  font-family: 'GTWalsheimPro-Bold', sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.link-style {
  color: #051D58;
  text-decoration: none;
  font-size: 14px;
}

.custom-link {
  display: flex;
  align-items: center;
  margin-bottom: 8px; 
}

.custom-link img {
  margin-right: 6px;
}

.custom-link a {
  color: inherit; 
  text-decoration: underline; 
}

.end-of-page {
  margin-top: 150px;
}

.resource-category-images {
  margin-bottom: 20px;
}
